import { Link } from "react-router-dom";

function TableContents() {
  const listProjects = [
    {
      id: 1,
      title: 'Projects',
      link: 'projects'
    },
    {
      id: 2,
      title: 'Wiki',
      link: 'wiki'
    },
    {
      id: 3,
      title: 'Telegram projects',
      link: 'telegram-projects'
    },
    {
      id: 4,
      title: 'Онлайн тестированиe',
      link: 'online-testing'
    },
    {
      id: 5,
      title: 'Финансы',
      child: [
        {
          id: 1,
          title: 'Сайт',
          link: 'finance'
        },
        {
          id: 2,
          title: 'Админка',
          link: 'finance/crm'
        }
      ]
    }
  ]

  const getList = (list) => list.map(project => {
    if (!project.child) {
      return (<li key={project.id.toString()}>
        <Link to={`${process.env.REACT_APP_PROJECT_URL}/${project.link}/`} target="_blank">{project.title}</Link>
      </li>)
    } else {
      return (<li key={project.id.toString()}>
        {project.title}
        <ul>
          {getList(project.child)}
        </ul>
      </li>)
    }
  })

  return (
    <nav>
      <ul>
        {getList(listProjects)}
      </ul>
    </nav>
  )
}

export default TableContents;
